nav {
  width: 90%;
  margin: auto;
  background-color: black;
  padding: 10px 0;
  font-family: Avenir;
  display: flex;
  justify-content: space-between;
}

.logoNavegacion {
  width: 130px;
  margin-left: 15px;
}
.enlacesNavegacion,
.enlacesEscritorio {
  display: flex;
  justify-content: center;
  align-items: center;
}

.enlacesNavegacion ul li,
.enlacesNavegacion ul li a {
  list-style: none;
  display: inline-block;
  margin: 0 15px;
  color: white;
  font-weight: 800;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
}
.enlacesNavegacion ul li {
  padding: 10px 0;
}

.iconoLenguaje {
  filter: invert(88%) sepia(97%) saturate(3528%) hue-rotate(323deg)
    brightness(86%) contrast(93%);
  width: 30px;
  height: 30px;
}
.ajustesLenguaje {
  position: relative;
}
.botonMostrarLenguaje {
  border: none;
  background-color: transparent;
  margin: 0 15px;
}
.selectorLenguaje {
  clip-path: polygon(
    90% 10%,
    100% 10%,
    100% 100%,
    0 100%,
    0 10%,
    80% 10%,
    85% 0
  );
  background-color: white;
  padding: 20px 0 10px 0;
  position: absolute;
  top: 32px;
  right: 12px;
  display: none;
  z-index: 10;
}

.selectorLenguaje button {
  width: 120px;
  background-color: transparent;
  border: none;
  padding: 10px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
}

.selectorLenguaje button:hover {
  background-color: var(--amarilloPrincipal);
}
.selectorLenguaje .botonActivo {
  background-color: var(--amarilloPrincipal);
}

/* SECTION Menu responsivo */

.menuMovil {
  background-color: var(--amarilloPrincipal);
  border: none;
  border-radius: 40px;
  /* position: fixed;
  bottom: 5%;
  left: 5%; */
  z-index: 4;
  display: none;
  -webkit-box-shadow: -4px 4px 5px -2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: -4px 4px 5px -2px rgba(0, 0, 0, 0.4);
  box-shadow: -4px 4px 5px -2px rgba(0, 0, 0, 0.4);
}
.menuMovil img {
  width: 40px;
}
.enlacesMoviles {
  display: none;
}

.enlaceActivo {
  border-bottom: 1px solid var(--amarilloPrincipal);
}

@media screen and (max-width: 1000px) {
  .enlacesEscritorio {
    display: none;
  }
  .menuMovil {
    display: block;
  }
  .enlacesMoviles {
    justify-content: space-evenly;
    display: flex;
    color: white;
    position: absolute;
    background-color: black;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    z-index: 3;
    text-align: center;
    top: 0;
    clip-path: circle(1px at 93% 9%);
    -webkit-clip-path: circle(1px at 93% 9%);
    transition: all 1s ease-out;
    pointer-events: none;
    overflow: hidden;
  }
  .enlacesMoviles.abierto {
    clip-path: circle(2000px at -10% 90%);
    -webkit-clip-path: circle(2000px at -10% 90%);
    pointer-events: all;
  }
  .enlacesMoviles li {
    opacity: 0;
    list-style: none;
  }
  .enlacesMoviles li a {
    text-decoration: none;
    font-size: 30px;
    color: var(--amarilloPrincipal);
  }
  .enlacesMoviles button {
    font-size: 20px;
  }
  .enlacesMoviles li:nth-child(1) {
    transition: all 0.5s ease 0.2s;
  }
  .enlacesMoviles li:nth-child(2) {
    transition: all 0.5s ease 0.4s;
  }
  .enlacesMoviles li:nth-child(3) {
    transition: all 0.5s ease 0.6s;
  }
  .enlacesMoviles li:nth-child(4) {
    transition: all 0.5s ease 0.8s;
  }

  .enlacesMoviles li:nth-child(5) {
    transition: all 0.5s ease 1s;
  }
  .enlacesMoviles li.fade {
    opacity: 1;
  }
  .ajustesLenguaje {
    display: flex;
  }
  .selectorLenguaje {
    top: 35px;
    right: 52px;
  }
}
