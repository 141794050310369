* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: Avenir;
  src: url("./assets/fonts/AvenirLTStd-Book.otf");
}
@font-face {
  font-family: Avenir;
  font-weight: 500;
  src: url("./assets/fonts/AvenirLTStd-Medium.otf");
}
@font-face {
  font-family: Avenir;
  font-weight: 800;
  src: url("./assets/fonts/AvenirLTStd-Roman.otf");
}
@font-face {
  font-family: Raleway;
  font-weight: 400;
  src: url("./assets/fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: Raleway;
  font-weight: bold;
  src: url("./assets/fonts/Raleway-Bold.ttf");
}

:root {
  --amarilloPrincipal: #d4af37;
  --grisPrincipal: #e5e5e5;
}
body {
  background-color: black;
}
body,
html {
  margin: 0;
  padding: 0;
}
ul {
  margin-bottom: 0;
}
.contenedor {
  max-width: 1440px;
  margin: auto;
}
.botonAmarillo {
  background-color: var(--amarilloPrincipal);
  border: none;
  color: white;
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 10px;
}
.botonAmarillo:hover {
  font-weight: 700 !important;
  letter-spacing: 3px;
  box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.3s ease 0s;
}
.botonAmarillo a {
  color: white;
  text-decoration: none;
  font-size: 13px;
}
.botonAmarillo a:hover {
  text-decoration: none;
}
p {
  margin-bottom: 0;
}
.sombraRedonda {
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 52px;
}
.iconoCerrar {
  position: absolute;
  top: -12px;
  right: -17px;
  max-width: 35px;
}
