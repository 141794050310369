.iconoCerrar {
  position: absolute;
  top: 10px;
  right: 10px;
}

.contenedorModal span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin: 20px 0;
  text-transform: uppercase;
}
form {
  width: 95%;
  margin: auto;
}
.contenedorModal img {
  width: 120px;
  margin: 30px auto;
}
.contenedorModal label {
  margin-top: 20px;
  font-weight: bold;
  font-family: Raleway;
  text-transform: uppercase;
}
.contenedorModal input,
.contenedorModal select {
  width: 100%;
  margin: 5px auto;
  border: 1px solid rgba(204, 204, 204, 0.829);
  padding: 10px 20px;
  border-radius: 10px;
}
.botonContrasena,
.contenedorModal p {
  border: none;
  background-color: transparent;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #c4c4c4;
  text-align: center;
}
.botonContrasena:hover {
  color: #8a8a8a;
}
.contenedorRegistrate {
  text-align: center;
}
input[type="submit"] {
  background-color: var(--amarilloPrincipal);
  border-radius: 10px;
  width: 50%;
  display: block;
  margin: 20px auto;
  color: white;
  text-transform: uppercase;
}
input[type="submit"]:hover {
  font-weight: 700 !important;
  letter-spacing: 3px;
  box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.3s ease 0s;
}
.contenedorModal p {
  display: inline-block;
  margin-bottom: 20px;
  margin-left: 15px;
}

.botonRegistrarse {
  border: none;
  background-color: transparent;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 22px;
  color: var(--amarilloPrincipal);
  margin-left: 5px;
}
button:hover {
  font-weight: 700 !important;
  letter-spacing: 3px;
  box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.3s ease 0s;
}
