.calendario {
  background-color: white;
  padding: 100px 0;
}
.contenedorTituloCalendario {
  padding: 40px;
}
.contenedorCalendario {
  padding: 80px;
}
.tradingview-widget-container__widget {
  height: 40rem;
}
.calendario h1 {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
