.ebook {
  background-color: white;
  text-align: center;
  padding: 100px 0;
  min-height: 800px;
}
.ebook span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
}
.contenedorLibros {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  margin: 50px auto;
  grid-gap: 20px;
}
.libro {
  background: #ffffff;
  padding-bottom: 30px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.libro img {
  width: 100%;
}
.libro span {
  margin-top: 30px;
  padding: 0 20px;
  display: block;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}
.modalEbook {
  width: 90% !important;
  max-width: 1170px;
  position: relative;
  margin: 30px auto;
  min-height: 500px !important;
}
.modalEbook iframe {
  height: 500px;
}
/* iframe::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

iframe::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

iframe::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
} */
@media screen and (max-width: 900px) {
  .contenedorLibros {
    grid-template-columns: 1fr;
  }
}
