.legales {
  background-color: white;
  width: 100%;
  padding: 0 0 80px 0;
  position: relative;
}
.tituloLegales {
  background-color: var(--amarilloPrincipal);
  text-align: center;
  padding: 40px 0;
}
.tituloLegales span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  color: white;
}
.botonesLegales {
  width: 90%;
  margin: 60px auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.botonesLegales button {
  background-color: transparent;
  border: none;
  padding: 30px 10px;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  border-left: 1px solid var(--amarilloPrincipal);
}
.botonesLegales button:first-of-type {
  border: none;
}
.botonesLegales button:hover {
  color: var(--amarilloPrincipal);
}
.bloquesLegales {
  width: 80%;
  height: 700px;
  overflow-y: auto;
  margin: auto;
  white-space: pre-line;
}

.bloquesLegales::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.bloquesLegales::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.bloquesLegales::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

.bloqueUnico {
  display: none;
}
.bloqueUnico span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 50px;
}
.bloqueUnico:first-of-type {
  display: block;
}
@media screen and (max-width: 900px) {
  .botonesLegales {
    grid-template-columns: repeat(3, 1fr);
  }
  .botonesLegales button {
    border: none;
  }
}
@media screen and (max-width: 550px) {
  .botonesLegales {
    grid-template-columns: repeat(2, 1fr);
  }
}
