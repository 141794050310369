* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: Avenir;
  src: url(/static/media/AvenirLTStd-Book.ecb0c2ae.otf);
}
@font-face {
  font-family: Avenir;
  font-weight: 500;
  src: url(/static/media/AvenirLTStd-Medium.4f995fa4.otf);
}
@font-face {
  font-family: Avenir;
  font-weight: 800;
  src: url(/static/media/AvenirLTStd-Roman.b1d7c6e0.otf);
}
@font-face {
  font-family: Raleway;
  font-weight: 400;
  src: url(/static/media/Raleway-Regular.84abe14c.ttf);
}
@font-face {
  font-family: Raleway;
  font-weight: bold;
  src: url(/static/media/Raleway-Bold.2f99a854.ttf);
}

:root {
  --amarilloPrincipal: #d4af37;
  --grisPrincipal: #e5e5e5;
}
body {
  background-color: black;
}
body,
html {
  margin: 0;
  padding: 0;
}
ul {
  margin-bottom: 0;
}
.contenedor {
  max-width: 1440px;
  margin: auto;
}
.botonAmarillo {
  background-color: #d4af37;
  background-color: var(--amarilloPrincipal);
  border: none;
  color: white;
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 10px;
}
.botonAmarillo:hover {
  font-weight: 700 !important;
  letter-spacing: 3px;
  box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.3s ease 0s;
}
.botonAmarillo a {
  color: white;
  text-decoration: none;
  font-size: 13px;
}
.botonAmarillo a:hover {
  text-decoration: none;
}
p {
  margin-bottom: 0;
}
.sombraRedonda {
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 52px;
}
.iconoCerrar {
  position: absolute;
  top: -12px;
  right: -17px;
  max-width: 35px;
}

.home {
  background-color: white;
  padding: 100px 0;
}
.intro {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: auto;
}
.textoIntro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.textoIntro span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
.textoIntro p {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: #000000;
}
.imagenIntro {
  text-align: center;
}
.imagenIntro img {
  width: 90%;
}
.videos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  width: 80%;
  margin: 50px auto;
}
.temaVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 52px;
  padding: 30px 0;
  flex-direction: column;
}
.temaVideo img {
  height: 70px;
  margin-bottom: 30px;
}
.temaVideo span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  display: block;
  color: #d4af37;
  margin-top: 10px;
}
.temaVideo p {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}
.contenedorVideos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 80px 30px;
  grid-gap: 20px;
}
.modalVideos {
  width: 90% !important;
  max-width: 1170px;
  position: relative;
  margin: 30px auto;
}
.modalVideos h5 {
  position: absolute;
  top: 50px;
  right: 30px;
  color: var(--amarilloPrincipal);
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 18px;
  text-align: right;
}
.listaVideos {
  max-height: 450px;
  overflow-y: auto;
}

.listaVideos::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.listaVideos::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.listaVideos::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
.contenedorVideos p {
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  color: #d4af37;
  grid-column: span 2;
}
.listaVideos h6,
.listaVideos span {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 0;
}
.listaVideos div {
  margin: 20px 0;
  padding: 10px 5px;
}
.activa {
  background-color: #d4af3750;
}
.reproductorVideos {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sinVideos {
  position: absolute;
  z-index: 1;
}
.reactPlayer {
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 1000px) {
  .contenedorVideos {
    grid-template-columns: 1fr;
    margin-top: 50px;
  }
  .contenedorVideos p {
    grid-column: span 1;
  }
  .listaVideos {
    max-height: 200px;
  }
}
@media screen and (max-width: 900px) {
  .videos {
    grid-template-columns: 1fr;
  }
  .intro {
    grid-template-columns: 1fr;
  }
}

.iconoCerrar {
  position: absolute;
  top: 10px;
  right: 10px;
}

.contenedorModal span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin: 20px 0;
  text-transform: uppercase;
}
form {
  width: 95%;
  margin: auto;
}
.contenedorModal img {
  width: 120px;
  margin: 30px auto;
}
.contenedorModal label {
  margin-top: 20px;
  font-weight: bold;
  font-family: Raleway;
  text-transform: uppercase;
}
.contenedorModal input,
.contenedorModal select {
  width: 100%;
  margin: 5px auto;
  border: 1px solid rgba(204, 204, 204, 0.829);
  padding: 10px 20px;
  border-radius: 10px;
}
.botonContrasena,
.contenedorModal p {
  border: none;
  background-color: transparent;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #c4c4c4;
  text-align: center;
}
.botonContrasena:hover {
  color: #8a8a8a;
}
.contenedorRegistrate {
  text-align: center;
}
input[type="submit"] {
  background-color: var(--amarilloPrincipal);
  border-radius: 10px;
  width: 50%;
  display: block;
  margin: 20px auto;
  color: white;
  text-transform: uppercase;
}
input[type="submit"]:hover {
  font-weight: 700 !important;
  letter-spacing: 3px;
  box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.3s ease 0s;
}
.contenedorModal p {
  display: inline-block;
  margin-bottom: 20px;
  margin-left: 15px;
}

.botonRegistrarse {
  border: none;
  background-color: transparent;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 22px;
  color: var(--amarilloPrincipal);
  margin-left: 5px;
}
button:hover {
  font-weight: 700 !important;
  letter-spacing: 3px;
  box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.3s ease 0s;
}

nav {
  width: 90%;
  margin: auto;
  background-color: black;
  padding: 10px 0;
  font-family: Avenir;
  display: flex;
  justify-content: space-between;
}

.logoNavegacion {
  width: 130px;
  margin-left: 15px;
}
.enlacesNavegacion,
.enlacesEscritorio {
  display: flex;
  justify-content: center;
  align-items: center;
}

.enlacesNavegacion ul li,
.enlacesNavegacion ul li a {
  list-style: none;
  display: inline-block;
  margin: 0 15px;
  color: white;
  font-weight: 800;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
}
.enlacesNavegacion ul li {
  padding: 10px 0;
}

.iconoLenguaje {
  -webkit-filter: invert(88%) sepia(97%) saturate(3528%) hue-rotate(323deg)
    brightness(86%) contrast(93%);
          filter: invert(88%) sepia(97%) saturate(3528%) hue-rotate(323deg)
    brightness(86%) contrast(93%);
  width: 30px;
  height: 30px;
}
.ajustesLenguaje {
  position: relative;
}
.botonMostrarLenguaje {
  border: none;
  background-color: transparent;
  margin: 0 15px;
}
.selectorLenguaje {
  -webkit-clip-path: polygon(
    90% 10%,
    100% 10%,
    100% 100%,
    0 100%,
    0 10%,
    80% 10%,
    85% 0
  );
          clip-path: polygon(
    90% 10%,
    100% 10%,
    100% 100%,
    0 100%,
    0 10%,
    80% 10%,
    85% 0
  );
  background-color: white;
  padding: 20px 0 10px 0;
  position: absolute;
  top: 32px;
  right: 12px;
  display: none;
  z-index: 10;
}

.selectorLenguaje button {
  width: 120px;
  background-color: transparent;
  border: none;
  padding: 10px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
}

.selectorLenguaje button:hover {
  background-color: var(--amarilloPrincipal);
}
.selectorLenguaje .botonActivo {
  background-color: var(--amarilloPrincipal);
}

/* SECTION Menu responsivo */

.menuMovil {
  background-color: var(--amarilloPrincipal);
  border: none;
  border-radius: 40px;
  /* position: fixed;
  bottom: 5%;
  left: 5%; */
  z-index: 4;
  display: none;
  box-shadow: -4px 4px 5px -2px rgba(0, 0, 0, 0.4);
}
.menuMovil img {
  width: 40px;
}
.enlacesMoviles {
  display: none;
}

.enlaceActivo {
  border-bottom: 1px solid var(--amarilloPrincipal);
}

@media screen and (max-width: 1000px) {
  .enlacesEscritorio {
    display: none;
  }
  .menuMovil {
    display: block;
  }
  .enlacesMoviles {
    justify-content: space-evenly;
    display: flex;
    color: white;
    position: absolute;
    background-color: black;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    z-index: 3;
    text-align: center;
    top: 0;
    clip-path: circle(1px at 93% 9%);
    -webkit-clip-path: circle(1px at 93% 9%);
    transition: all 1s ease-out;
    pointer-events: none;
    overflow: hidden;
  }
  .enlacesMoviles.abierto {
    clip-path: circle(2000px at -10% 90%);
    -webkit-clip-path: circle(2000px at -10% 90%);
    pointer-events: all;
  }
  .enlacesMoviles li {
    opacity: 0;
    list-style: none;
  }
  .enlacesMoviles li a {
    text-decoration: none;
    font-size: 30px;
    color: var(--amarilloPrincipal);
  }
  .enlacesMoviles button {
    font-size: 20px;
  }
  .enlacesMoviles li:nth-child(1) {
    transition: all 0.5s ease 0.2s;
  }
  .enlacesMoviles li:nth-child(2) {
    transition: all 0.5s ease 0.4s;
  }
  .enlacesMoviles li:nth-child(3) {
    transition: all 0.5s ease 0.6s;
  }
  .enlacesMoviles li:nth-child(4) {
    transition: all 0.5s ease 0.8s;
  }

  .enlacesMoviles li:nth-child(5) {
    transition: all 0.5s ease 1s;
  }
  .enlacesMoviles li.fade {
    opacity: 1;
  }
  .ajustesLenguaje {
    display: flex;
  }
  .selectorLenguaje {
    top: 35px;
    right: 52px;
  }
}

footer {
  background-color: black;
  width: 100%;
  padding: 20px 0;
  position: relative;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoFooter {
  width: 130px;
}
.logosSociales {
  width: 130px;
  display: flex;
  justify-content: space-evenly;
}
.logosSociales img {
  width: 20px;
}
.enlacesPrivacidad {
  color: white;
  display: grid;
  grid-template-areas:
    "cuentasFooter legalesFooter"
    "acercaFooter legalesFooter"
    "plataformaFooter legalesFooter";
}
.cuentasFooter {
  grid-area: cuentasFooter;
}
.acercaFooter {
  grid-area: acercaFooter;
}
.plataformaFooter {
  grid-area: plataformaFooter;
}
.legalesFooter {
  grid-area: legalesFooter;
}
.enlacesPrivacidad div ul li,
.enlacesPrivacidad span {
  list-style: none;
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
}
.enlacesPrivacidad div ul li a {
  text-decoration: none;
  color: rgb(194, 194, 194);
}

.enlacesPrivacidad div span {
  font-weight: 500;
  font-size: 14px;
}
.enlacesPrivacidad div {
  padding: 10px 20px;
}
/* SECTION  PAGOS*/
.contenedorPagos {
  background-color: #ccc;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}
.contenedorPagos img {
  width: 35px;
  margin: 5px;
}

@media screen and (max-width: 900px) {
  .contenedorPagos {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    text-align: center;
  }
  footer {
    flex-direction: column;
  }
  .redesSociales {
    text-align: center;
  }
  .logosSociales {
    margin: 10px auto;
  }
  .enlacesPrivacidad {
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .contenedorPagos {
    grid-template-columns: repeat(5, 1fr);
  }
  .enlacesPrivacidad {
    grid-template-areas:
      "cuentasFooter"
      "acercaFooter"
      "plataformaFooter"
      "legalesFooter";
  }
  .enlacesPrivacidad div ul li,
  .enlacesPrivacidad span {
    font-size: 10px;
  }
  .enlacesPrivacidad div span {
    font-size: 12px;
  }
}

.legales {
  background-color: white;
  width: 100%;
  padding: 0 0 80px 0;
  position: relative;
}
.tituloLegales {
  background-color: var(--amarilloPrincipal);
  text-align: center;
  padding: 40px 0;
}
.tituloLegales span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  color: white;
}
.botonesLegales {
  width: 90%;
  margin: 60px auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.botonesLegales button {
  background-color: transparent;
  border: none;
  padding: 30px 10px;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  border-left: 1px solid var(--amarilloPrincipal);
}
.botonesLegales button:first-of-type {
  border: none;
}
.botonesLegales button:hover {
  color: var(--amarilloPrincipal);
}
.bloquesLegales {
  width: 80%;
  height: 700px;
  overflow-y: auto;
  margin: auto;
  white-space: pre-line;
}

.bloquesLegales::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.bloquesLegales::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.bloquesLegales::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

.bloqueUnico {
  display: none;
}
.bloqueUnico span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 50px;
}
.bloqueUnico:first-of-type {
  display: block;
}
@media screen and (max-width: 900px) {
  .botonesLegales {
    grid-template-columns: repeat(3, 1fr);
  }
  .botonesLegales button {
    border: none;
  }
}
@media screen and (max-width: 550px) {
  .botonesLegales {
    grid-template-columns: repeat(2, 1fr);
  }
}

.ebook {
  background-color: white;
  text-align: center;
  padding: 100px 0;
  min-height: 800px;
}
.ebook span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
}
.contenedorLibros {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  margin: 50px auto;
  grid-gap: 20px;
}
.libro {
  background: #ffffff;
  padding-bottom: 30px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.libro img {
  width: 100%;
}
.libro span {
  margin-top: 30px;
  padding: 0 20px;
  display: block;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}
.modalEbook {
  width: 90% !important;
  max-width: 1170px;
  position: relative;
  margin: 30px auto;
  min-height: 500px !important;
}
.modalEbook iframe {
  height: 500px;
}
/* iframe::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

iframe::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

iframe::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
} */
@media screen and (max-width: 900px) {
  .contenedorLibros {
    grid-template-columns: 1fr;
  }
}

.calendario {
  background-color: white;
  padding: 100px 0;
}
.contenedorTituloCalendario {
  padding: 40px;
}
.contenedorCalendario {
  padding: 80px;
}
.tradingview-widget-container__widget {
  height: 40rem;
}
.calendario h1 {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}

.glosario {
  background-color: white;
}
.contenedorGlosario {
  text-align: center;
  padding: 100px 0;
}
.contenedorGlosario span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
.botonesGlosario button {
  background-color: transparent;
  border: none;
  padding: 10px;
  margin: 10px;
  transition: all 0.3s ease;
  text-transform: uppercase;
}
.botonesGlosario button:hover {
  background-color: var(--amarilloPrincipal);
  color: white;
}

.auxiliarLetras {
  max-height: 500px;
  overflow-y: auto;
}

.auxiliarLetras::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.auxiliarLetras::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.auxiliarLetras::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
.letraUnica {
  text-align: left;
  display: none;
  padding: 0 20px;
}
.auxiliarLetras div span {
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  color: #d4af37;
}
.auxiliarLetras div p {
  margin-bottom: 20px;
}
.bloqueLetras {
  padding: 10px;
  width: 80%;
  margin: 40px auto;
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 52px;
}
#bloquea {
  display: block;
}

@media screen and (max-width: 900px) {
  .bloqueLetras {
    width: 95%;
    padding: 50px 25px;
  }
}

