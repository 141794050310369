.home {
  background-color: white;
  padding: 100px 0;
}
.intro {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: auto;
}
.textoIntro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.textoIntro span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
.textoIntro p {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: #000000;
}
.imagenIntro {
  text-align: center;
}
.imagenIntro img {
  width: 90%;
}
.videos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  width: 80%;
  margin: 50px auto;
}
.temaVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 52px;
  padding: 30px 0;
  flex-direction: column;
}
.temaVideo img {
  height: 70px;
  margin-bottom: 30px;
}
.temaVideo span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  display: block;
  color: #d4af37;
  margin-top: 10px;
}
.temaVideo p {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}
.contenedorVideos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 80px 30px;
  grid-gap: 20px;
}
.modalVideos {
  width: 90% !important;
  max-width: 1170px;
  position: relative;
  margin: 30px auto;
}
.modalVideos h5 {
  position: absolute;
  top: 50px;
  right: 30px;
  color: var(--amarilloPrincipal);
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 18px;
  text-align: right;
}
.listaVideos {
  max-height: 450px;
  overflow-y: auto;
}

.listaVideos::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.listaVideos::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.listaVideos::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
.contenedorVideos p {
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  color: #d4af37;
  grid-column: span 2;
}
.listaVideos h6,
.listaVideos span {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 0;
}
.listaVideos div {
  margin: 20px 0;
  padding: 10px 5px;
}
.activa {
  background-color: #d4af3750;
}
.reproductorVideos {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sinVideos {
  position: absolute;
  z-index: 1;
}
.reactPlayer {
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 1000px) {
  .contenedorVideos {
    grid-template-columns: 1fr;
    margin-top: 50px;
  }
  .contenedorVideos p {
    grid-column: span 1;
  }
  .listaVideos {
    max-height: 200px;
  }
}
@media screen and (max-width: 900px) {
  .videos {
    grid-template-columns: 1fr;
  }
  .intro {
    grid-template-columns: 1fr;
  }
}
