.glosario {
  background-color: white;
}
.contenedorGlosario {
  text-align: center;
  padding: 100px 0;
}
.contenedorGlosario span {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
.botonesGlosario button {
  background-color: transparent;
  border: none;
  padding: 10px;
  margin: 10px;
  transition: all 0.3s ease;
  text-transform: uppercase;
}
.botonesGlosario button:hover {
  background-color: var(--amarilloPrincipal);
  color: white;
}

.auxiliarLetras {
  max-height: 500px;
  overflow-y: auto;
}

.auxiliarLetras::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.auxiliarLetras::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.auxiliarLetras::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
.letraUnica {
  text-align: left;
  display: none;
  padding: 0 20px;
}
.auxiliarLetras div span {
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  color: #d4af37;
}
.auxiliarLetras div p {
  margin-bottom: 20px;
}
.bloqueLetras {
  padding: 10px;
  width: 80%;
  margin: 40px auto;
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 52px;
}
#bloquea {
  display: block;
}

@media screen and (max-width: 900px) {
  .bloqueLetras {
    width: 95%;
    padding: 50px 25px;
  }
}
